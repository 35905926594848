footer {
	background-color: $footer-bg-color;
	padding: 40px 0;

	@include media-breakpoint-down(md) {
		padding: 40px 0 35px 0;
	}

	.footer-content {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	img {
		width: 150px;
		height: 150px;

		@include media-breakpoint-down(md) {
			width: 80px;
			height: 80px;
		}
	}

	p {
		margin: 0;
		margin-left: 40px;
		font-size: 16px;
		line-height: 24px;
		color: #ECECEC;

		@include media-breakpoint-down(md) {
			font-size: 14px;
			margin-left: 0px;
			margin-top: 18px;
		}
	}
}