.headroom {
	will-change: transform;
	transition: transform 200ms linear;
}
.headroom--pinned {
	transform: translateY(0%);
}
.headroom--unpinned {
	transform: translateY(-100%);
}

header {
	width: 100%;
	background-color: transparent;
	position: fixed;
	top: 0;
	z-index: 5;
	padding: 31px 0;

	@include media-breakpoint-down(md) {
		padding: 0px;
	}
	@include media-breakpoint-only(md) {
		padding: 25px 0;
	}

	.logo-holder{
		margin-right: 70px;
		font-size: 0px;

		@include media-breakpoint-only(xl) {
			margin-right: 63px;
		}

		@include media-breakpoint-only(lg) {
			margin-right: 30px;
		}

		img {
			width: 170px;

			@include media-breakpoint-only(md) {
				width: 152px;
			}
			@include media-breakpoint-down(md) {
				width: 125px;
			}
		}
	}
}

.navbar {
	justify-content: start;
	padding-top: 0px;
	padding-bottom: 0px;

	@include media-breakpoint-down(lg) {
		justify-content: space-between;
	}

	.navbar-nav {
		display: flex;
		flex-direction: row;
		margin-right: auto;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: center;
			margin-top: 20px;
			margin-right: 0px;
		}

		.nav-link {
			padding: 0px;
			padding-left: 20px;
			padding-right: 20px;
			&:hover, &:active {
				color: $accent-color;
			}

			@include media-breakpoint-down(xl) {
					padding: 0px 13px;
			}
		}
	}
}

.nav-primary {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.nav-mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	overflow: hidden;
	opacity: 0;
	z-index: -10;

	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(lg) {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		padding: 0 12px;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		z-index: -10;
		transform: translateY(-100%);
	}
	@include media-breakpoint-only(md) {
		padding: 0px;
	}

	.btn-style {
		@include media-breakpoint-down(md) {
			margin: 16px auto 60px auto;
			width: 200px;
		}
		@include media-breakpoint-only(md) {
			margin: 20px auto 60px auto;
			width: 210px;
		}
	}
	
	.navbar-nav {
		margin-top: 20px;

		.nav-link {
			text-align: center;
			padding: 20px 0;

			&:hover {
				color: $main-heading;
			}
		}
	}
}

.navbar-toggler {
	padding: 0;
	height: 22px;
	width: 30px;
	border-radius: 0px;

	@include media-breakpoint-only(md) {
		height: 28px;
		width: 38px;
	}
	
	&:focus {
		box-shadow: none;
		border: none;
	}
}

.mobile-menu-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 18px;
	padding-bottom: 40px;

	@include media-breakpoint-only(xs) {
		padding-left: 0px;
		padding-right: 0px;
	}
	@include media-breakpoint-only(sm) {
		padding-left: 12px;
		padding-right: 12px;
	}
	@include media-breakpoint-only(md) {
		padding-top: 25px;
		padding-left: 12px;
		padding-right: 12px;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}

	a {
		font-size: 0px;
	}
}

.mobile-menu-logo {
	@include media-breakpoint-down(md) {
		width: 125px;
		height: auto;
	}
	@include media-breakpoint-only(md) {
		width: 152px;
		height: auto;
	}
}

.mobile-menu-open-light, .mobile-menu-open-dark, .mobile-menu-close {
	cursor: pointer;
}

.mobile-menu-open-light {
	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}

.mobile-menu-open-dark {
	display: none;
	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}

.mobile-menu-close {
	@include media-breakpoint-only(md) {
		width: 28px;
		height: 28px;
		margin-right: 5px;
	}
	@include media-breakpoint-down(md) {
		width: 22px;
		height: 22px;
		margin-right: 4px;
	}
}

.mobile-menu-accent {
	@include media-breakpoint-down(md) {
		width: 700px;
		position: relative;
		height: auto;
		margin-top: auto;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.tablet-menu-accent {
	@include media-breakpoint-down(md) {
		display: none;
	}
	@include media-breakpoint-only(md) {
		width: 105%;
		position: relative;
		height: auto;
		margin-top: auto;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
}