@import '~bootstrap/scss/bootstrap';
@import 'variables';
@import 'base';
@import 'fonts';
@import 'mixins';
@import 'utilities';
@import 'animations';
@import 'header';
@import 'pages/home';
@import 'form';
@import 'footer';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	scroll-behavior: auto;
}

body, #app {
	overflow-x: hidden;
}

.grecaptcha-badge { 
    visibility: hidden;
}
