section.hero {
	overflow: hidden;

	.container {
		@include media-breakpoint-down(md) {
			padding: 0px;
		}
	}

	h1, p {
		z-index: 2;
	}

	h1 {
		@include media-breakpoint-only(md) {
			padding-right: 1rem;
		}
	}

	.hero-image-holder {
		order: 2;

		@include media-breakpoint-down(md) {
			order: -1;
			height: 420px;
		}
	}

	.hero-content {
		padding-top: 110px;
		height: 820px;
		display: flex;
		justify-content: center;
		flex-direction: column;

		@include media-breakpoint-only(md) {
			margin: 0px;
			padding-top: 55px;
			height: 671px;
		}
		@include media-breakpoint-down(md) {
			margin: 10px 0 80px 0;
			padding: 0 12px;
			height: auto;
		}

		.hyphenated-text-group {
			margin-top: 24px;

			@include media-breakpoint-down(md) {
				margin-top: 16px;
			}

			.hyphen {
				margin-top: 14px;

				@include media-breakpoint-down(lg) {
					margin-top: 12px;
				}
			}

			p {
				padding-right: 4rem;

				@include media-breakpoint-only(xxl) {
					padding-right: 9rem;
				}
				@include media-breakpoint-down(xl) {
					padding-right: 0px;
				}
				@include media-breakpoint-down(lg) {
					margin-bottom: 0px;
				}
			}
		}

		.explore-link {
			z-index: 2;
			width: fit-content;
			margin-top: 35px;
			display: flex;
			align-items: center;
			text-decoration: none;
			font-weight: $medium;
			cursor: pointer;
	
			&:hover {
				transition: 0.15s color ease;
				color: $main-heading;
	
				@include media-breakpoint-up(lg) {
					color: $sub-heading;
	
					.link-image {
						background-image: url('/images/arrow-light.svg');
					}
				}
			}
	
			.link-image {
				background-image: url('/images/arrow.svg');
				background-size: cover;
				background-repeat: no-repeat;
				width: 40px;
				height: 40px;
				margin-left: 15px;
				transition: 0.15s all ease;
	
				@include media-breakpoint-only(md) {
					margin-left: 12px;
					width: 40px;
					height: 40px;
				}
				@include media-breakpoint-down(md) {
					margin-left: 12px;
					width: 35px;
					height: 35px;
				}
			}
		}
	}

	.accent {
		background: #232B2D;
		width: 100vw;
		left: 130px;
		height: 820px;
		z-index: -10;

		@include media-breakpoint-only(xxl) {
			left: 126px;
		}

		@include media-breakpoint-only(md) {
			left: 52px;
			right: unset;
			height: 671px;
		}
		@include media-breakpoint-down(md) {
			left: 53%;
			height: 420px;
		}
	}

	.hero-accent-image {
		width: 138%;
		height: 600px;
		object-fit: cover;
		top: 140px;
		right: 0px;
		z-index: -1;

		@include media-breakpoint-only(xxl) {
			height: 600px;
			top: 140px;
			right: 0px;
		}
		@include media-breakpoint-only(md) {
			width: 135%;
			height: 531px;
			top: 90px;
			left: unset;
			right: 0px;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			height: 308px;
			top: 80px;
			left: 0px;
			right: 0px;
			padding: 0 12px;
		}
	}
}

.brand-logos-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 62px;

	@include media-breakpoint-down(md) {
		display: grid;
		align-items: center;
		justify-items: center;
		grid-template-rows: repeat(2, auto);
		grid-template-columns: repeat(8, auto);
		margin-top: 0px;
		row-gap: 20px;
	}
	@include media-breakpoint-only(md) {
		margin-top: 50px;
	}

	img {
		width: auto;

		@include media-breakpoint-down(md) {
			margin: 0 auto;
			padding: 0 8px;
		}
	}

	.brand-logo-1 {
		height: 32px;
		grid-column: 1 / 3;

		@include media-breakpoint-down(lg) {
			height: 18px;
		}
	}
	.brand-logo-2 {
		height: 45px;
		grid-column: 3 / 5;

		@include media-breakpoint-down(lg) {
			height: 29px;
		}
	}
	.brand-logo-3 {
		height: 45px;
		grid-column: 5 / 7;

		@include media-breakpoint-down(lg) {
			height: 28px;
		}
	}
	.brand-logo-4 {
		height: 45px;
		grid-column: 7 / 9;

		@include media-breakpoint-down(lg) {
			height: 24px;
		}
	}
	.brand-logo-5 {
		height: 45px;
		grid-column: 2 / 4;

		@include media-breakpoint-down(lg) {
			height: 28px;
		}
	}
	.brand-logo-6 {
		height: 35px;
		grid-column: 4 / 6;

		@include media-breakpoint-down(lg) {
			height: 24px;
		}
	}
	.brand-logo-7 {
		height: 45px;
		grid-column: 6 / 8;

		@include media-breakpoint-down(lg) {
			height: 28px;
		}
	}
}

section.services {
	position: relative;
	padding-top: 120px;
	padding-bottom: 150px;

	@include media-breakpoint-only(md) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 75px;
		padding-bottom: 0px;
	}

	.accent-circle-lg {
		top: -450px;
		left: -80%;
		height: 780px;
		width: auto;
		z-index: -3;
		@include media-breakpoint-only(xl) {
			left: -100%;
		}
		@include media-breakpoint-only(lg) {
			left: -110%;
		}
	}
	.accent-circle-lg-tablet {
			top: -334px;
			left: -470px;
			height: 668px;
			width: auto;
			z-index: -3;
		}

	.accent-circle-lg-mobile {
		top: -270px;
		left: -250px;
		height: 374px;
		width: auto;
		z-index: -3;
	}

	.accent-circle-sm {
		height: 390px;
		right: -80%;
		bottom: -150px;
		z-index: -3;
		@include media-breakpoint-only(xl) {
			right: -100%;
		}
		@include media-breakpoint-only(lg) {
			right: -110%;
		}
	}

	.accent-circle-sm-tablet {
		height: 200px;
		width: auto;
		right: -100%;
		bottom: -105px;
		z-index: -3;
	}

	.accent-circle-sm-mobile {
		height: 200px;
		width: auto;
		right: -185px;
		bottom: 0px;
		z-index: -3;
	}

	.hyphenated-text-group {
		p {
			margin: 0;
		}
	}

	p {
		margin: 25px 0 50px 0;

		@include media-breakpoint-only(md) {
			margin: 22px 0 40px 0;
		}
		@include media-breakpoint-down(md) {
			margin: 19px 0 33px 0;
		}
	}

	.talent-image {
		padding-left: 40px;
		width: 100%;
		height: 580px;
		object-fit: cover;

		@include media-breakpoint-only(xxl) {
			height: 630px;
		}
		@include media-breakpoint-down(md) {
			padding: 0 15px;
			height: 360px;
			margin-bottom: 30px;
		}
		@include media-breakpoint-only(md) {
			padding-left: 15px;
			padding-right: 0px;
			margin-bottom: 0px;
			height: 430px;
		}
	}

	.talent-info {
		padding-left: 43px;
		padding-top: 100px;

		@include media-breakpoint-down(xxl) {
			padding-top: 0px;
			align-self: center;
		}

		@include media-breakpoint-down(md) {
			padding-left: 0px;
			padding-top: 0px;
		}
		@include media-breakpoint-only(md) {
			padding-left: 6px;
			padding-top: 0px;
		}
	}

	.strategy-row {
		margin-top: 70px;

		@include media-breakpoint-only(md) {
			margin-top: 70px;
		}
		@include media-breakpoint-down(md) {
			margin-top: 50px;
		}
	}

	.strategy-image-holder {
		order: 2;

		@include media-breakpoint-down(md) {
			order: -1;
		}
	}

	.strategy-image {
		width: 100%;
		padding-right: 32px;
		height: 580px;
		object-fit: cover;
		object-position: 40% 50%;

		@include media-breakpoint-only(xxl) {
			height: 630px;
		}
		@include media-breakpoint-down(md) {
			padding: 0 15px;
			margin-bottom: 30px;
			margin-top: 0px;
			height: 360px;
		}
		@include media-breakpoint-only(md) {
			padding-left: 0px;
			padding-right: 15px;
			margin-bottom: 0px;
			margin-top: 0px;
			height: 430px;
		}
	}
	
	.strategy-info {
		padding-right: 43px;
		padding-top: 100px;

		@include media-breakpoint-down(xxl) {
			padding-top: 0px;
			align-self: center;
		}
		@include media-breakpoint-down(md) {
			padding-right: 0px;
			padding-top: 0px;
			margin-bottom: 70px;
		}
		@include media-breakpoint-only(md) {
			padding-right: 6px;
			padding-top: 0px;
			margin-bottom: 0px;
		}
	}

	.accent-numbers {
		width: 72px;
		z-index: 2;
		top: 20px;

		@include media-breakpoint-down(md) {
			width: 10%;
			min-width: 40px;
			max-width: 60px;
		}
		@include media-breakpoint-only(md) {
			width: 10%;
			min-width: 40px;
			max-width: 60px;
		}
	}

	.decorative-numbers-01 {
		left: 12px;
	}

	.decorative-numbers-02 {
		right: 12px;
	}
}

section.case-study {
	background-color: #4B525E;
	padding: 100px 0;

	@include media-breakpoint-down(lg) {
		padding: 80px 0 58px 0;
	}

	.case-study-quote {
		background: url('/images/case_study.jpg');
		background-position: 50% 50%;
		background-size: cover;
		margin-right: 20px;
		padding: 60px;

		@include media-breakpoint-down(md) {
			padding: 40px 20px;
			margin-right: 0px;
		}
		@include media-breakpoint-only(md) {
			background-position: 50% 60%;
			padding: 50px 100px;
			margin-right: 0px;
		}
	}

	.quote-holder {
		background-color: rgba($color: white, $alpha: 0.75);
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		padding: 40px;

		@include media-breakpoint-down(lg) {
			padding: 30px 25px 28px
		}

		p {
			margin: 22px 0 20px 0;
		}

		.hyphenated-text-group {
			grid-template-columns: 32px auto;
			justify-content: flex-end;

			@include media-breakpoint-down(lg) {
				grid-template-columns: 25px auto;
			}

			.hyphen {
				margin-top: 14px;

				@include media-breakpoint-down(md) {
					margin-top: 11px;
				}
			}

			p {
				font-weight: $medium;
				font-size: 20px;
				line-height: 30px;
				color: $main-heading;
				margin: 0;
				padding-left: 15px;
	
				@include media-breakpoint-down(md) {
					font-size: 18px;
					line-height: 23px;
					padding-left: 10px;
				}
			}
		}
	}

	.case-study-details {
		color: white;
		align-self: center;
		padding-left: 20px;

		@include media-breakpoint-only(md) {
			padding-left: 0px;
			margin-top: 40px
		}
		@include media-breakpoint-down(md) {
			padding-left: 0px;
			margin-top: 30px
		}

		h3 {
			font-family: $font-secondary;
			font-weight: $bold;
			font-size: 20px;
			line-height: 20px;
			color: $accent-color-lt;
			text-transform: uppercase;
			margin-left: 76px;
			letter-spacing: 1px;

			@include media-breakpoint-down(md) {
				font-size: 19px;
				margin-left: 45px;
				margin-bottom: 0px;
			}
			@include media-breakpoint-only(md) {
				font-size: 19px;
				margin-left: 50px;
			}
		}

		h2 {
			color: $white;
			margin: 25px 0;

			@include media-breakpoint-down(md) {
				margin: 22px 0
			}
			@include media-breakpoint-only(md) {
				margin: 18px 0px
			}
		}

		p {
			color: $white;
		}
	}
}

@supports not (backdrop-filter: blur(10px) or (-webkit-backdrop-filter: blur(10px))) {
	.who-we-are { 
		background-color: rgba($color: $white, $alpha: 0.9) !important;
	}

	.quote-holder {
		background-color: rgba($color: $white, $alpha: 0.9) !important;
	}
}

section.about {
	padding: 110px 0 90px;

	@include media-breakpoint-down(md) {
		padding: 80px 0 50px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 80px 0 70px 0;
	}

	.who-we-are-col {
		height: 568px;

		@include media-breakpoint-down(md) {
			height: auto;
			padding: 0 30px;
		}
		@include media-breakpoint-only(md) {
			height: auto;
			padding: 0px;
		}
	}

	.who-we-are {
		align-self: center;
		box-shadow: -2px 2px 4px 0 rgba(75, 82, 94, 0.12);
		background-color: rgba($color: white, $alpha: 0.75);
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		padding: 40px 55px 40px 50px;

		@include media-breakpoint-down(md) {
			padding: 25px 25px 18px;
			margin-top: 48%;
		}
		@include media-breakpoint-only(md) {
			padding: 40px 50px 36px;
			width: calc(83.33% - 24px);
			margin: 204px auto auto auto;
		}

		p {
			margin-bottom: 12px;
		}

		p:first-of-type {
			margin: 16px 0 12px 0;
			font-weight: $regular;
		}

		p:last-of-type {
			margin-bottom: 0px;
		}
	}

	.about-image-holder {
		right: 0px;
		z-index: -1;

		img {
			max-height: 568px;
			width: 100%;
			object-fit: cover;
			object-position: 50% 0%;

			@include media-breakpoint-only(md) {
				max-height: 330px;
			}
		}
	}
}

section.core-values {
	margin-bottom: 90px;

	@include media-breakpoint-only(md) {
		margin-bottom: 55px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 34px;
	}

	h3 {
		margin-bottom: 25px;

		@include media-breakpoint-down(md) {
			margin-bottom: 18px;
		}
		@include media-breakpoint-only(md) {
			margin-bottom: 22px;
		}
	}
	
	h4 {
		margin: 20px 0;
		text-transform: none;

		@include media-breakpoint-only(md) {
			margin: 13px 0;
			grid-column: 2/3;
		}
		@include media-breakpoint-down(md) {
			margin: 10px 0;
			grid-column: 2/3;
		}
	}

	.hyphenated-text-group {
		grid-template-columns: 165px auto;
		margin-bottom: 36px;

		@include media-breakpoint-down(md) {
			grid-template-columns: 50px auto;
			margin-bottom: 18px
		}
		@include media-breakpoint-only(md) {
			grid-template-columns: 85px auto;
			margin-bottom: 13px
		}
	}

	.core-value-holder {
		@include media-breakpoint-down(lg) {
			display: grid;
			grid-template-columns: 45px auto;
			grid-template-rows: auto auto;
			align-items: center;
			column-gap: 20px;
			margin-bottom: 12px;

			@include media-breakpoint-down(md) {
				margin-bottom: 16px;
			}
			@include media-breakpoint-only(md) {
				margin-bottom: 15px;
			}
		}

		img {
			width: 50px;
			height: 50px;

			@include media-breakpoint-down(lg) {
				width: 45px;
				height: 45px;
			}
			@include media-breakpoint-only(md) {
				margin-top: 15px;
			}
		}

		p {
			@include media-breakpoint-down(lg) {
				grid-column: 2/3;
				grid-row: 2/3;
			}
			@include media-breakpoint-only(md) {
				margin-bottom: 0px;
			}
		}
	}
}

.company-trait {
	.hyphenated-text-group p{
		margin-bottom: 12px;
	}

	@include media-breakpoint-down(md) {
		margin-top: 24px;
	}
}

section.philosophy {
	margin-bottom: 90px;

	@include media-breakpoint-down(md) {
		margin-bottom: 38px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 70px;
	}

	img {
		width: 100%;
		height: 436px;
		object-fit: cover;
		object-position: 50% 50%;
		padding-right: 24px;

		@include media-breakpoint-down(lg) {
			padding-right: 0px;
		}
		@include media-breakpoint-only(md) {
			height: 425px;
		}
		@include media-breakpoint-down(md) {
			padding-right: 0px;
			height: 285px;
		}
	}

	.company-trait {
		padding-left: 24px;

		@include media-breakpoint-down(lg) {
			padding-left: 0px;
		}
		@include media-breakpoint-only(md) {
			padding: 35px 0;
		}
	}
}

section.promise {
	margin-bottom: 110px;

	@include media-breakpoint-down(md) {
		margin-bottom: 58px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 80px;
	}

	.promise-image-holder {
		@include media-breakpoint-down(md) {
			order: -1;
		}
	}

	img {
		width: 100%;
		height: 436px;
		object-fit: cover;
		object-position: 50% 28%;
		padding-left: 24px;

		@include media-breakpoint-down(lg) {
			padding-left: 0px;
		}
		@include media-breakpoint-only(md) {
			height: 425px;
			object-position: 50% 0%;
		}
		@include media-breakpoint-down(md) {
			padding-left: 0px;
			height: 285px;
		}
	}

	.company-trait {
		padding-right: 24px;

		@include media-breakpoint-down(lg) {
			padding-right: 0px;
		}
	}
}

section.faqs {
	background-color: $bg-lt-gray;
	padding: 110px 0 100px 0;

	@include media-breakpoint-down(md) {
		padding: 70px 0 53px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 70px 0 78px 0;
	}

	h2, .hyphenated-text-group p {
		@include media-breakpoint-down(md) {
			max-width: calc(100% - 48px);
		}
	}

	img {
		top: -72px;
		right: 0px;
		width: auto;
		height: 195px;

		@include media-breakpoint-down(lg) {
			height: 120px;
		}
		@include media-breakpoint-down(md) {
			top: -48px;
		}
		@include media-breakpoint-only(md) {
			top: -45px;
		}
	}

	.hyphenated-text-group {
		margin: 20px 0 40px 0;

		@include media-breakpoint-down(md) {
			margin: 20px 0 30px;
		}
		@include media-breakpoint-only(md) {
			margin: 22px 0 28px;
		}

		p {
			@include media-breakpoint-down(md) {
				margin-bottom: 0px;
			}
		}
	}

	.accordion-item {
		background: $white;
		box-shadow: 0px 2px 4px 0 rgba(75, 82, 94, 0.12);
		margin-bottom: 17px;
		border: none;

		@include media-breakpoint-down(md) {
			margin-bottom: 15px;
		}

		h2 {
			max-width: 100%;
		}

		.accordion-button {
			padding: 32px;
			color: $paragraph-text;
			margin-bottom: 0px;

			@include media-breakpoint-down(md) {
				padding: 15px 12px 18px 13px;
			}
			@include media-breakpoint-only(md) {
				padding: 28px 25px 25px 25px;
			}
			
			&::before {
				content: "";
				background-image: url("/images/plus.svg");
				background-repeat: no-repeat;
				width: 35px;
				height: 35px;
				background-position: 50% 50%;
				background-size: contain;
				align-self: flex-start;
				flex-shrink: 0;
				margin-right: 18px;
				margin-top: -2px;

				@include media-breakpoint-down(md) {
					width: 22px;
					height: 22px;
					margin-top: 1px;
					margin-right: 10px;
				}
				@include media-breakpoint-only(md) {
					margin-top: -3px;
				}
			}

			&:focus {
				box-shadow: none;
			}

			&.collapsed::before {
				background-image: url("/images/plus.svg");
			}

			&.collapsed:hover::before {
				background-image: url("/images/plus-highlighted.svg");
			}

			&:not(.collapsed) {
				background-color: $white;
				color: $paragraph-text;
				box-shadow: none;
			}

			&:not(.collapsed)::before {
				background-image: url("/images/minus.svg");
			}

			&::after {
				content: none;
				background-image: none;
			}
		}

		.accordion-body {
			padding: 0px 32px 20px 32px;

			@include media-breakpoint-down(md) {
				padding: 0px 12px 20px 13px;
			}
			@include media-breakpoint-only(md) {
				padding: 0px 25px 18px;
			}

			p {
				@include media-breakpoint-only(md) {
					margin-top: 4px;
				}
				@include media-breakpoint-down(md) {
					margin-bottom: 0px;
				}
			}
		}
	}
}

section.sticky-footer {
	position: fixed;
	bottom: 0;
	z-index: 10;
	background: $footer-bg-color;
	padding: 15px 0px;
	width: 100%;
	overflow-x: hidden;

	.btn-style {
		@include media-breakpoint-down(lg) {
			padding: 16.5px 0;
		}
	}

	@include media-breakpoint-down(lg) {
		background-color: transparent;
		padding: 0px;
	}

	.container {
		@include media-breakpoint-down(lg) {
			padding: 0px;
			max-width: 100% !important;
		}
	}

	h4 {
		font-family: $font-secondary;
		font-weight: $regular;
		font-size: 24px;
		line-height: 42px;
		letter-spacing: 0px;
		text-transform: none;
		margin-bottom: 0;
		color: #f4f4f4;
		padding-right: 1rem;

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.btn-style {
		@include media-breakpoint-down(lg) {
			width: 100%;
			border-radius: 0px;
		}
	}
}

section.contact {
	position: relative;

	.contact-form-accent-holder {
		order: -1;

		@include media-breakpoint-down(lg) {
			order: 2;
		}
	}

	.accent {
		width: 35%;
		height: 100%;
		left: 0;
		background-color: $main-heading;
		z-index: -2;

		@include media-breakpoint-down(md) {
			height: 440px;
			bottom: 0;
		}
		@include media-breakpoint-only(md) {
			height: 676px;
			bottom: 0;
		}
	}
	
	.contact-form-left {
		margin: 120px 0 0 0;

		@include media-breakpoint-down(md) {
			margin: 0px;
			height: 440px;
		}
		@include media-breakpoint-only(md) {
			margin: 0px;
			height: 676px;
		}

		.socials-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-self: flex-end;
			margin-right: 35px;

			@include media-breakpoint-down(md) {
				align-self: center;
				margin-right: 20px;
			}
			@include media-breakpoint-only(md) {
				align-self: flex-start;
				margin-top: 70px;
				margin-right: 35px;
			}

			a {
				width: 40px;
				height: 40px;
				margin: 11px 0;
				background-size: cover;

				@include media-breakpoint-down(md) {
					margin: 7px 0;
					width: 35px;
					height: 35px;
				}
			}

			.linkedin-icon {
				background-image: url('/images/linkedin.svg');
				margin-top: 0px;
				&:hover {
					background-image: url('/images/linkedin-light.svg');
				}
			}
			.facebook-icon {
				background-image: url('/images/facebook.svg');
				&:hover {
					background-image: url('/images/facebook-light.svg');
				}
			}
			.twitter-icon {
				background-image: url('/images/twitter.svg');
				&:hover {
					background-image: url('/images/twitter-light.svg');
				}
			}

			p {
				writing-mode: vertical-lr;
				transform: rotate(180deg);
				margin: 0;
				margin-top: 20px;
				font-family: $font-secondary;
				font-weight: $medium;
				font-size: 18px;
				letter-spacing: 1px;
				line-height: 28px;
				color: $white;
				
				@include media-breakpoint-only(md) {
					font-size: 17px;
					letter-spacing: 0.94px;
					line-height: 17px;
					margin-top: 19px;
				}
				@include media-breakpoint-down(md) {
					font-size: 17px;
					margin-top: 22px;
				}
			}
		}

		img {
			width: 425px;
			height: 846px;
			object-fit: cover;
			object-position: 50% 75%;

			@include media-breakpoint-only(xxl) {
				width: 500px;
				height: 820px;
			}
			@include media-breakpoint-only(lg) {
				width: 370px;
				height: 874px;
				object-position: 0% 75%;
			}
			@include media-breakpoint-only(md) {
				width: 100%;
				height: 536px;
				margin: auto 0;
				object-position: 50% 100%;
			}
			@include media-breakpoint-down(md) {
				width: 100%;
				height: 340px;
				margin: auto 0;
				object-position: 50% 100%;
			}
		}
	}
}