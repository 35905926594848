.form-container {
	margin-top: 120px;

	@include media-breakpoint-down(md) {
		margin-top: 70px;
	}
	@include media-breakpoint-only(md) {
		margin-top: 80px;
	}

	.form-header {
		padding-top: 15px;

		@include media-breakpoint-down(lg) {
			padding-top: 0px;
		}

		@include media-breakpoint-only(md) {
			width: 91.66%;
		}

		h4 {
			font-family: $font-secondary;
			font-weight: $bold;
			font-size: 20px;
			line-height: 20px;
			color: $accent-color;
			margin-left: 75px;
			letter-spacing: 1px;

			@include media-breakpoint-down(md) {
				margin-left: 45px;
				margin-bottom: 0px;
				font-size: 19px;
			}
			@include media-breakpoint-only(md) {
				margin-left: 50px;
			}
		}

		h2 {
			margin: 24px 0;

			@include media-breakpoint-down(md) {
				margin: 15px 0 18px 0;
			}
			@include media-breakpoint-only(md) {
				margin: 22px 0 25px 0;
			}
		}

		.hyphenated-text-group {
			margin-bottom: 24px;

			@include media-breakpoint-down(md) {
				margin-bottom: 18px;
			}
			@include media-breakpoint-only(md) {
				margin-bottom: 28px;
			}
		}
	}

	form {
		@include media-breakpoint-only(md) {
			display: flex;
			flex-wrap: wrap;
		}

		button {
			margin-top: 5px;
		}

		label {
			margin-bottom: 11px;
		}

		input:not([type='submit']), select {
			outline: none;
			border: none;
			border-bottom: 1px solid #c1c1c1;
			font-weight: $medium;
			padding-bottom: 6px;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			background: transparent;
			background-image: url("/images/select-icon.svg");
			background-repeat: no-repeat;
			background-position-x: 100%;
			background-position-y: 5px;
		}

		input:active, input:focus, select:active, select:active {
			border-bottom: 1px solid $main-heading;
		}

		.input-row {
			display: flex;
			justify-content: space-between;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
	
			@include media-breakpoint-only(md) {
				width: 100%;
			}

			.input-group {
				display: flex;
				flex-direction: column;
				margin-bottom: 30px;

				@include media-breakpoint-down(lg) {
					margin-bottom: 25px;
				}

				&:nth-child(1) {
					@include media-breakpoint-only(md) {
						margin-right: 15px;
					}
				}

				&:nth-child(2) {
					margin-left: 30px;

					@include media-breakpoint-down(md) {
						margin-left: 0px;
					}
					@include media-breakpoint-only(md) {
						margin-left: 15px;
					}

					@include media-breakpoint-up(lg) {
						&.input-group-specialism {
							margin-left: 0px;
						}
					}
				}
			}
		}

		.referral-text {
			strong {
				font-weight: $medium;
			}
			margin-top: 50px;
			margin-bottom: 120px;

			@include media-breakpoint-down(md) {
				margin-top: 40px;
				margin-bottom: 50px;
			}
			@include media-breakpoint-only(md) {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}

		.form-feedback {
			display: flex;
			padding: 12px 15px;
			align-items: flex-start;

			img {
				width: 16px;
				height: 16px;
				margin-right: 8px;
                margin-top: 2px;
			}

			p {
				margin: 0;
				font-family: $font-secondary;
				font-weight: $medium;
				font-size: 16px;
				line-height: 20px;
			}
		}

		.form-success {
			background-color: $bg-success;
			font-family: $font-secondary;
			font-weight: $medium;
			margin-bottom: 35px;
			display: none;

			img {
				margin-top: 4px;
			}

			p {
				color: $paragraph-text;
			}

			@include media-breakpoint-only(md) {
				width: 100%;
			}
		}
		.form-error {
			background-color: $bg-error;

			p {
				color: $error-text;
				font-family: $font-secondary;
				font-weight: $medium;
			}
		}
	}
}