.btn-style {
	border-radius: 50px;
	width: 160px;
	padding: 9px 0;
	text-align: center;
	text-decoration: none;
	background-color: $accent-color;
	border: 2px solid transparent;
	color: $white;
	font-family: $font-secondary;
	font-weight: $semibold;
	font-size: 19px;
	line-height: 28px;
	flex-shrink: 0;

	&:hover {
		color: $white;

		@include media-breakpoint-up(lg) {
			border: 2px solid $accent-color;
			color: $main-heading;
			background-color: $white;
		}
	}
}

.btn-transition {
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.link-transition {
	transition: background-image .15s ease-in-out;
}

.hyphenated-text-group {
	display: grid;
	grid-template-columns: 52px auto;

	@include media-breakpoint-down(md) {
		grid-template-columns: 30px auto;
	}
	@include media-breakpoint-only(md) {
		grid-template-columns: 35px auto;
	}

	.hyphen {
		margin-top: 12px;
		border-top: 2px solid $accent-color;

		@include media-breakpoint-down(lg) {
			margin-top: 11px;
		}
	}

	p {
		padding-left: 24px;

		@include media-breakpoint-down(lg) {
			padding-left: 15px;
		}
	}
}

.scrolled-header-style {
	background-color: $white;
	box-shadow: -2px 2px 4px 0 rgba(75, 82, 94, 0.12);
	padding: 20px 0;

	@include media-breakpoint-down(md) {
		padding: 18px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 25px 0;
	}
}

.unscrolled-header-style {
	background-color: transparent;
	box-shadow: none;
	padding: 31px 0;

	@include media-breakpoint-down(md) {
		padding: 18px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 25px 0;
	}
}

.scrolled-footer {
	bottom: 0px !important;
	transition: 0.25s ease all;
}

.unscrolled-footer {
	bottom: -114px !important;
	transition: 0.25s ease all;

	@include media-breakpoint-only(md) {
		bottom: -135px !important;
	}
}

.accent-circle, .menu-accent {
	opacity: 0.7;
}