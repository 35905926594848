@font-face {
	font-family: $font-primary;
	src: url('/fonts/Versailles-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: $font-secondary;
	src: url('/fonts/PPNeueMontreal-Light.ttf');
	font-weight: 300;
}

@font-face {
	font-family: $font-secondary;
	src: url('/fonts/PPNeueMontreal-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: $font-secondary;
	src: url('/fonts/PPNeueMontreal-Medium.ttf');
	font-weight: 500;
}