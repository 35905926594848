body {
	font-family: $font-primary;
}

h1, .h1 {
	font-weight: $regular;
	font-size: 55px;
	line-height: 68px;
	color: $main-heading;
	letter-spacing: 4.5px;
	text-transform: uppercase;
	margin-bottom: 0px;

	@include media-breakpoint-only(md) {
		font-size: 44px;
		line-height: 54px;
		letter-spacing: 2.8px;
	}
	@include media-breakpoint-down(md) {
		font-size: 38px;
		letter-spacing: 2.85px;
		line-height: 48px;
	}
}

h2, .h2 {
	font-weight: $regular;
	font-size: 45px;
	letter-spacing: 3.68px;
	line-height: 56px;
	color: $main-heading;
	text-transform: uppercase;

	@include media-breakpoint-only(md) {
		font-size: 38px;
		line-height: 44px;
		letter-spacing: 2px;
	}

	@include media-breakpoint-down(md) {
		font-size: 35px;
		letter-spacing: 2.5px;
		line-height: 42px;
	}
}

h3, .h3 {
	font-weight: $regular;
	font-size: 36px;
	letter-spacing: 2.95px;
	line-height: 45px;
	color: $main-heading;
	text-transform: uppercase;

	@include media-breakpoint-only(md) {
		font-size: 33px;
		line-height: 40px;
		letter-spacing: 1.3px;
	}
	@include media-breakpoint-down(md) {
		font-size: 30px;
		letter-spacing: 1.8px;
		line-height: 37px;
	}
}

h4, .h4 {
	font-weight: $regular;
	font-size: 30px;
	line-height: 32px;
	letter-spacing: 2.4px;
	color: $main-heading;
	text-transform: uppercase;

	@include media-breakpoint-only(md) {
		font-size: 25px;
		line-height: 32px;
		letter-spacing: 1.04px;
	}
	@include media-breakpoint-down(md) {
		font-size: 25px;
		letter-spacing: 1px;
		line-height: 32px;
	}
}

h5, .h5 {
	font-family: $font-secondary;
	font-weight: $medium;
	font-size: 22px;
	line-height: 30px;
	color: $paragraph-text;

	@include media-breakpoint-only(md) {
		font-size: 21px;
		line-height: 28px;
	}
	@include media-breakpoint-down(md) {
		font-size: 19px;
		line-height: 25px;
	}
}

.lead-copy {
	font-family: $font-secondary;
	font-weight: $medium;
	text-transform: none;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0px;
	color: $sub-heading;
	margin: 25px 0;

	@include media-breakpoint-only(md) {
		font-size: 22px;
		line-height: 28px;
		margin: 20px 0 18px 0;
	}
	@include media-breakpoint-down(md) {
		font-size: 20px;
		line-height: 28px;
		margin: 15px 0;
	}
}

.testimonial-copy {
	font-family: $font-secondary;
	font-weight: $light;
	font-size: 25px;
	line-height: 32px;
	color: #363D3F;

	@include media-breakpoint-down(lg) {
		font-size: 23px;
		line-height: 30px;
	}
}

.paragraph-large {
	font-size: 20px;
	line-height: 28px;

	@include media-breakpoint-down(lg) {
		font-size: 18px;
		line-height: 26px;
	}
}

p, .p {
	font-family: $font-secondary;
	font-weight: $light;
	font-size: 18px;
	line-height: 26px;
	color: $paragraph-text;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 24px;
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: 12px;
	}
}

.links-copy {
	font-family: $font-secondary;
	font-weight: $medium;
	font-size: 19px;
	line-height: 28px;
	color: $main-heading;
}

label {
	font-family: $font-secondary;
	font-weight: $regular;
	font-size: 16px;
	line-height: 24px;
	color: $main-heading;
}

input, select {
	font-family: $font-secondary;
	font-weight: $medium;
	font-size: 17px;
	line-height: 24px;
	color: $main-heading;
}