$main-heading: #232B2D;
$sub-heading: #42474E;
$paragraph-text: #1e1e1d;
$font-primary: 'Versailles';
$font-secondary: 'Montreal';
$footer-bg-color: #363D3F;
$accent-color: #B48F41;
$accent-color-lt: #D1B981;
$error-text: #AC1111;
$bg-error: #FFEBEB;
$bg-success: #F3EDDB;
$bg-lt-gray: #FAFAFA;
$white: #FFFFFF;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;