[data-aos="fade-right"] {
	&.explore-link {
		@include media-breakpoint-down(md) {
			@media (max-height: 896px) {
				&.aos-animate{transition-delay: .5s !important;}
			}
		}
	}

	&.hyphenated-text-group {
		@include media-breakpoint-down(md) {
			@media (max-height: 740px) {
				&.aos-animate{transition-delay: 0.5s !important;}
			}
		}
	}
}

[data-aos="fade-up"] {
	&.brand-logo-1 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 2.5s !important;}
		}
	}
	&.brand-logo-2 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 2.6s !important;}
		}
	}
	&.brand-logo-3 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 2.7s !important;}
		}
	}
	&.brand-logo-4 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 2.8s !important;}
		}
	}
	&.brand-logo-5 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 2.9s !important;}
		}
	}
	&.brand-logo-6 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 3s !important;}
		}
	}
	&.brand-logo-7 {
		@include media-breakpoint-only(md) {
			&.aos-animate {transition-delay: 3.1s !important;}
		}
	}
}